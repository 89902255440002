import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import anchorme from "anchorme";


export interface DocItem {
  content: string;
}

@Component({
  selector: 'app-card',
  template: `
    <main *ngIf="card" [innerHTML]="card.content"></main>
    <ngx-spinner *ngIf="!card"
      bdOpacity=0.9
      size="default"
      color="#fff"
      type="ball-clip-rotate">
      <p style="color: #333" > Loading... </p>
    </ngx-spinner>
  `,
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  private card: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
  ) {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    try {
      let doc: AngularFirestoreDocument<DocItem> = db.doc<DocItem>(`/cards/${id}`);

      doc
        .valueChanges()
        .subscribe({
          next: val => {
            this.card = val;
          },
          error: err => {
            this.router.navigate(['/error/404']);
          }
        })
    } catch (err) {
      this.router.navigate(['/error/404']);
    }
  }

}
