import { environment } from '../../../../environments/environment';

// config
function getActionCodeSettings(email): any {
  return {
    url: `http://${environment.host}/console?action=auth&email=${email}`,
    handleCodeInApp: true,    // This must be true.
    iOS: {},
    android: {},
    dynamicLinkDomain: 'yufengzone.page.link',
  }
}

export async function login() {
  try {
    let auth = this.afAuth.auth
    let email: string = await this.input('email: ')

    await auth.sendSignInLinkToEmail(email, getActionCodeSettings(email))

    this.println('')
  } catch (err) {
    console.error('Failed to login.', err)
  }
}

// https://firebase.google.com/docs/auth/web/email-link-auth?authuser=1#complete_sign_in_with_the_email_link
export async function conformEmailLogin(auth, email) {
  if (auth.isSignInWithEmailLink(window.location.href)) {

    // var email = window.localStorage.getItem('emailForSignIn');
    // if (!email)
    //   email = window.prompt('Please provide your email for confirmation');
    
    let result = await auth.signInWithEmailLink(email, window.location.href)
    console.log(result)
  }
}

export function whoami() {
  let user = this.afAuth.auth.currentUser
  let displayName = user.displayName ? user.displayName : user.email

  this.println(displayName)
} 