import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ArticleComponent } from './article/article.component';
import { ArticleListComponent } from './article/article-list.component';
import { CardComponent } from './card/card.component';
import { ConsoleComponent } from './console/console.component'
import { PhotoComponent } from './photo/photo.component';


const routes: Routes = [
  {
    path: 'articles/:id',
    component: ArticleComponent
  },
  /*
  {
    path: 'articles',
    component: ArticleListComponent
  },
  */
  {
    path: 'cards/:id',
    component: CardComponent
  },
  /*
  {
    path: 'photos',
    component: PhotoComponent
  },
  */
  {
    path: 'console',
    component: ConsoleComponent
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }

