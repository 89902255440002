import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: [`
    :host {
        width: 90vw;
        min-width: 350px;
        max-width: 1200px;
        min-height: 100vh;
        padding-top: 1rem;
        padding-bottom: 5rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
  `]
})
export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('Welcome')
  }

}
