import axios from 'axios';
import { Injectable } from '@angular/core';


const userAgent = 'yufeng.zone/web-cilent';


@Injectable({
  providedIn: 'root'
})
export class Server {

  private http = axios.create({
    headers: {
      'User-Agent': userAgent
    }
  });

  constructor() {
    // debugger;
  }

  public setAuth(token: string) {
    this.http.defaults.headers.common['Authorization'] = `token ${token}`;
  }

}
