import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stream-of-consciousness',
  templateUrl: './stream-of-consciousness.component.html',
  styleUrls: ['./stream-of-consciousness.component.scss']
})
export class StreamOfConsciousnessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
