import { Component, OnInit } from '@angular/core';
import { ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

// import { User } from '../../base/user';
import { Term } from './term';
import { conformEmailLogin } from './commands';

@Component({
  selector: 'app-terminal',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `* {
      overflow: hidden !important;
    }`
  ],
  styleUrls: [
    '../../../../node_modules/xterm/css/xterm.css'
  ],
  template: `
    <main #terminal></main>
  `,
})
export class ConsoleComponent implements OnInit {

  @ViewChild('terminal', {static: true}) terminalDiv: ElementRef;

  private term: Term;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth
  ) { }

  async ngOnInit() {
    this.term = new Term(this.terminalDiv, this.router, this.afAuth);

    let action = this.route.snapshot.queryParamMap.get('action');

    if (action == 'auth') {
      let email = this.route.snapshot.queryParamMap.get('email');
      await conformEmailLogin(this.afAuth.auth, email)
      this.router.navigate(['/console'])
      this.term.println(`Login success: ${email}`)
    }
  }

}