import { Component, OnInit } from '@angular/core';
import { User } from '../base';


@Component({
  selector: 'app-about',
  styles: [`
    main {
      font-size: 1em;
      color: #eee;

      p a {
        color: #eee;
      }
    }
    :host {
      color: #f0f0f0;
      width: 90vw;
      max-width: 720px;
      font-size: 1.5rem;
      display: flex;
      padding-bottom: 5rem;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  `],
  template: `
    <main>
      <h1>Yufeng</h1>
      <p><a href="mailto:{{email}}">{{email}}</a></p>
    </main>
  `,
})
export class AboutComponent implements OnInit {

  private name: string;
  private email: string;

  constructor(
    public user: User
  ) { }

  ngOnInit() {
    this.email = 'i@yufeng.zone'
  }

}
