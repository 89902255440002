import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import axios from 'axios';
import { Term } from '../term';

//
// command context
//

export class CommandContext {
  private api: string = '/api/console'

  constructor(
    private term: Term,
    private router: Router,
    private afAuth: AngularFireAuth,
  ) { }

  // exec the command from remote
  async exec(command: string, ...args: any[]) {
    let res = await axios.post(this.api, {
      command: command,
      arguments: args
    })
    for (let op of res.data.operator) {
      eval('(() => { this' + op + ' })').bind(this)()
    }
    return 'OK'
  }

  print(str: string): void {
    this.term.print(str)
  }

  println(str: string): void {
    this.term.println(str)
  }

  input(str: string): Promise<any> {
    return this.term.input(str)
  }

  password(str: string): Promise<any> {
    return this.term.password(str)
  }
}


//
// commands
//

export async function remotexec(command: string, ...args: Array<string>) {
  try {
    await this.exec(command, ...args);
  } catch(err) {
    console.error('Error to execute remote command.', err)
  }
}

export function goto(...args) {
  this.print(args[0]);
  this.router.navigate([args[0]])
}

export function echo(...args) {
  // console.log(this)
  this.print(args.join(' '))
}

export function date() {
  this.print((new Date()).toString())
}

export function exec(...args) {
  let cmd = args.join(' ')
  this.print(eval(cmd))
}

export function qr(...args: Array<string>) {
  window.location.href = `/qr/b64/${btoa(args.join(' '))}`
}

//
// export other commands
//
export { login, conformEmailLogin, whoami,  } from './login';