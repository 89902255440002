import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireDatabaseModule } from '@angular/fire/database';

import { NgxSpinnerModule } from 'ngx-spinner';

import { MainRoutingModule } from './main-routing.module'
import { RouteReuseService } from './main-route-reuse.strategy'

import { ArticleComponent } from './article/article.component';
import { ArticleListComponent } from './article/article-list.component';
import { CardComponent } from './card/card.component';
import { ConsoleComponent } from './console/console.component';
import { PhotoComponent } from './photo/photo.component';

import { environment } from '../../environments/environment';
import { StreamOfConsciousnessComponent } from './stream-of-consciousness/stream-of-consciousness.component';

// export environment;


@NgModule({
  declarations: [
    ArticleComponent,
    CardComponent,
    ConsoleComponent,
    PhotoComponent,
    ArticleListComponent,
    StreamOfConsciousnessComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    // firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    // AngularFireDatabaseModule,
    // route
    MainRoutingModule,
    // ..
    NgxSpinnerModule
  ],
  providers: [{
    provide: RouteReuseStrategy,
    useClass: RouteReuseService
  }]
})
export class MainModule { }
