import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from '@angular/router';

// SEE: https://itnext.io/cache-components-with-angular-routereusestrategy-3e4c8b174d5f
export class RouteReuseService implements RouteReuseStrategy {

  private storedRouteHandles = new Map<string, DetachedRouteHandle>();
  private allowRetrivePaths = new Set([
    'console'
  ]);

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    let path = this.getPath(route)
    return this.allowRetrivePaths.has(path)
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    let path = this.getPath(route)
    this.storedRouteHandles.set(path, detachedTree)
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    let path = this.getPath(route);
    if (!this.allowRetrivePaths.has(path))
      return false;
    return this.storedRouteHandles.has(this.getPath(route));
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    let path = this.getPath(route)
    return this.storedRouteHandles.get(path) as DetachedRouteHandle
  }

  shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return before.routeConfig === curr.routeConfig
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig !== null && route.routeConfig.path !== null)
      return route.routeConfig.path
    return ''
  }

}