import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import { Server, User } from '../../base';
import { Article } from './model';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent {

  private article: Article | null = null;

  constructor(
    public user: User,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
  ) {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    let doc: AngularFirestoreDocument<Article> = this.db.doc<Article>(`/articles/${id}`);

    doc
      .valueChanges()
      .subscribe({
        next: val => {
          this.article = new Article(val.title, val.createaAt, val.content, val.contentType)
        },
        error: err => {
          this.router.navigate(['/error/404']);
        }
      })
  }

}
