import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import * as stringWidth from 'string-width';

import { Terminal } from 'xterm';

import * as commands from './commands';
import { CommandContext } from './commands';


interface Monitor {
  print(data: string): void;
  println(data: string): void;
}


const LeftChar: string = "[D";
const RightChar: string = "[C";

export class Term implements Monitor {
  //
  // term consts
  //
  public readonly WELCOME = 'yufeng.zone console'

  // ,,,
  private cursorRelativePosition: number = 0;
  private term: Terminal;

  constructor(
    public terminalDiv: ElementRef,
    private router: Router,
    private afAuth: AngularFireAuth,
  ) {
    this.term = this.create(terminalDiv)

    this.term.onKey(this.onKey.bind(this))
    this.term.onData(this.onData.bind(this))
    this.initBuffer()
  }

  //
  // create term instance
  //
  private create(terminalDiv: ElementRef): Terminal {
    // console.debug('create term')

    let term = new Terminal({
      cursorBlink: false,
      // useStyle: true,
      scrollback: 60,
      lineHeight: 1.3,
      rows: 30,
      allowTransparency: true,
      theme: {
        background: '#00000000',
      }
    })

    term.open(terminalDiv.nativeElement);
    term.writeln(this.WELCOME);
    term.writeln('');
    // focus term
    term.focus()

    return term;
  }

  // property
  get prefix(): string {
    if (this.isPasswordInput)
      return '* '
    else
      return '> '
  }

  // "key event"  handler write input by keyboard
  // "data event" handler write input by input softwar or paste
  private isCtrlInput: boolean = false;
  private isPasswordInput: boolean = false;

  /**
   * 
   *  basic input
   * 
   */

  // key event handler function
  private onKey(event) {
    // console.debug('key> ', key, ev, key.charCodeAt(0))
    let [key, ev] = [event.key, event.domEvent];

    this.isCtrlInput = true;  // set to false at final else section

    // <Enter>
    if (ev.code == 'Enter') {
      if (this.sendPassword) {
        this.sendPassword(this.buffer.join(''))
        this.buffer = new Array<string>();
      } else if (this.sendInput) {
        this.sendInput(this.buffer.join(''))
        this.buffer = new Array<string>();
      } else {
        this.sendTypein(this.buffer.join(''))
      }
    }
    // <Backspace>
    else if (ev.code == 'Backspace') {
      let index = this.buffer.length + this.cursorRelativePosition;
      let deleted = this.buffer.splice(index - 1, 1)[0]
      if (deleted) {
        this.term.write('\x08'.repeat(stringWidth(deleted)) + '\x1b[J')
        // this.output.display('\x08\x08\x1b[J'.repeat(deleted.length))
        // this.buffer = new Array<string>();
        this.refreshLine()
      }

    }
    // Ctrl presed
    else if (ev.ctrlKey) {
      switch (ev.code) {
        case 'KeyL': {      // Ctrl-l to start of the line
          this.term.clear();
          this.isCtrlInput = true;
          break;
        }
        case 'KeyA': {      // Ctrl-a to start of the line
          // term.cols(this.PS1.length); break;
          let beforeCurrsorLength = stringWidth(
            this.buffer.slice(0, this.buffer.length + this.cursorRelativePosition).join('')
          )
          for (let i = 0; i < beforeCurrsorLength; i++) {
            this.term.write(LeftChar);
          }
          this.cursorRelativePosition = -this.buffer.length;
          break;
        }
        case 'KeyE': {      // Ctrl-a to start of the line
          // term.cols(this.PS1.length); break;
          let afterCurrsorLength = stringWidth(
            this.buffer.slice(this.buffer.length + this.cursorRelativePosition).join('')
          )
          for (let i = 0; i < afterCurrsorLength; i++) {
            this.term.write(RightChar);
          }
          this.cursorRelativePosition = 0;
          break;
        }
        case 'KeyC': {      // Ctrl-c to start of the line
          this.term.writeln('');
          this.initBuffer()
          break;
        }
      }
    }
    // up / down / left / right
    else if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(ev.code)) {
      this.cursorAction(ev.code, key)
    }
    // Do nothing, juse echo
    else {
      // console.debug(`none-control key> "${key}"`)
      this.isCtrlInput = false;
    }
  }

  // data event handler function
  private onData(data) {
    // console.debug(`data> "${data}" : ${data.charCodeAt(0)}`)

    // let term = this.term;
    if (!this.isCtrlInput)
      this.typein(data)

    this.isCtrlInput = false;
  }

  // typein
  private typein(data: string) {
    // console.debug('input > ', data)

    // SEE: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/@@iterator
    // use Symble.iterator avoid special single character (like emjoi)
    //   iterator to double incorrect characters
    let iterator = data[Symbol.iterator]()
    let theChar = iterator.next();

    while (!theChar.done) {
      // <any> avoid `insert` type check
      (this.buffer as any).insert(this.buffer.length + this.cursorRelativePosition, theChar.value)
      if (!this.sendPassword)
        this.term.write(theChar.value)
      theChar = iterator.next();
    }

    this.refreshLine()

    // console.debug('buffer> ', this.buffer)
    // console.debug('cursorRelativePosition> ', this.cursorRelativePosition)
  }

  private refreshLine() {
    let needFlush = this.buffer.slice(this.buffer.length + this.cursorRelativePosition).join('')
    this.term.write(needFlush)

    if (this.cursorRelativePosition != 0)
      this.term.write(LeftChar.repeat(stringWidth(needFlush)))
  }


  /**
   *  buffer & cursor
   */
  private buffer: Array<string>;
  private histrryBuffers: Array<Array<string>>;
  // I don't know how to type ArrowLeft key code, assign it
  private printlnWhenSned: boolean;

  private sendInput: (...args) => void | null = null;
  private sendPassword: (...args) => void | null = null;

  // send to handler
  private sendTypein(command) {
    this.term.writeln('');
    this.exec(command)
    this.initBuffer()
  }

  // exec command
  private exec(input: string) {
    // console.debug('exec> ', input)
    if (input[0] === '/') {
      var [cmd, args] = ['goto', [input]];
    } else {
      var [cmd, ...args] = input.split(/\s/);
    }

    if (cmd == "") return;

    let context = new CommandContext(this, this.router, this.afAuth)

    try {
      let result = (() => {
        if (commands[cmd])
          return commands[cmd].bind(context)(...args)
        else
          return commands.remotexec.bind(context)(cmd, ...args);
      })()

      if (result instanceof Promise) {
        result.then(() => {
          this.term.writeln('');
          this.initBuffer()
        })
      }
    } catch (err) {
      // TypeError
      if (err instanceof TypeError) {
        console.error(`%ccommand not found "${cmd}"`, 'color: red')
      } else {
        console.error(`%cexecute command "${cmd}" error:\n%c${err}`, 'color: red', 'color: pink')
      }
    }
  }

  // new buffer
  private initBuffer() {
    // https://stackoverflow.com/a/15621345
    /* Syntax:
       array.insert(index, value1, value2, ..., valueN) */
    Array.prototype['insert'] = function (index) {
      this.splice.apply(this, [index, 0].concat(
        Array.prototype.slice.call(arguments, 1)));
      return this;
    }
    this.buffer = new Array<string>();
    // reset
    this.cursorRelativePosition = 0;
    //
    if (this.printlnWhenSned) {
      this.printlnWhenSned = false;
      this.term.writeln('');
    }
    if (!this.sendInput && !this.sendPassword) {
      this.term.write(this.prefix);
    }
  }

  // cursor
  private cursorAction(action, key: string) {
    switch (action) {
      case 'ArrowUp': {
        throw 'not support';
        break;
      }
      case 'ArrowDown': {
        throw 'not support';
        break;
      }
      case 'ArrowLeft': {
        if (-this.cursorRelativePosition >= this.buffer.length)
          return
        this.term.write(
          key.repeat(
            stringWidth(
              this.buffer[this.buffer.length + this.cursorRelativePosition - 1]
            )
          )
        )
        this.cursorRelativePosition--
        break;
      }
      case 'ArrowRight': {
        if (this.cursorRelativePosition >= 0)
          return
        this.term.write(
          key.repeat(
            stringWidth(
              this.buffer[this.buffer.length + this.cursorRelativePosition]
            )
          )
        )
        this.cursorRelativePosition++
        break;
      }
    }
  }


  /**
   * 
   *  public methods
   * 
   */

  public print(str: any) {
    this.term.write(str.toString())
    this.printlnWhenSned = true
  }

  public println(str: any) {
    this.term.writeln(str.toString())
  }

  // input call by command
  public input(str: string) {
    this.term.write(str)

    return new Promise((resolve, reject) => {
      this.sendInput = (...args) => {
        this.sendInput = null;
        this.term.writeln('')
        resolve(...args)
      }
    })
  }

  // password call by command
  public password(str: string) {
    this.term.write(str)

    return new Promise((resolve, reject) => {
      this.sendPassword = (...args) => {
        this.sendPassword = null;
        this.term.writeln('')
        resolve(...args)
      }
    })
  }

}